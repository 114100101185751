<template>
  <v-App>





   <!-- Your item content here -->

    <table>
<template v-for="irows in rows">


  <v-lazy
                             :options="{
                                  threshold: .5
                              }"
                              :key="irows.key"
                              class="fill-height">
      <tr >


        <td  v-for="index in cols" :key="index.key">
          Hello<br>


          <button class="button">Accept Terms</button>
        </td>

      </tr>
      </v-lazy>


</template>
    </table>




  <div class="about">




  </div>
  </v-App>
</template>
<script>
export default {
  data() {
    return {
      isActive:true,
      cols:[1,2,3,4,5,6,7,8,9,10,12,13,1,2,3,4,5],
      rows:[1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3,4,5,1,2,3]
    }
  }
}

</script>
<style>
.fill-height{height:100px;}
.button{
  height: 36px;
    min-width: 64px;
    padding: 0 16px;
        background-color: #f5f5f5;
        font-size: 0.875rem;


}
</style>
